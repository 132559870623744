* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #130f40;
  background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  max-width: 570px;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}

h1 {
  font-size: 400%;
}

h2 {
  font-size: 350%;
}

h3 {
  font-size: 200%;
}

mark {
  background: #cefe21;
  -webkit-text-fill-color: #000;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1.4px solid #cefe21;
  border-radius: 50px;
}

a {
  color: #cefe21;
}

input {
  border-radius: 8px;
  padding: 10px;
  width: 100%;
}

label {
  color: white;
  margin-bottom: 4px;
  border-bottom: 1px solid #cefe21;
  color: #cefe21;
}

input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #cefe21;
  border-radius: 0;
  margin-top: 6px;
  color: white;
}

button {
  border: none;
}

.gradient-primary {
  background: linear-gradient(to right, #d81593 0%, #3c23dd 51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-secondary {
  background: linear-gradient(to right, #f9fd45 0%, #ff556a 51%, #ff556a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-tertiary {
  background: linear-gradient(to right, #c6f35c 0%, #54ffa3 51%, #54ffa3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subheader {
  background: -webkit-linear-gradient(326deg, #9795ef 0%, #f9c5d1 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: larger;
  font-weight: 400;
}

.btn-grad {
  background-image: linear-gradient(to right, #d81593 0%, #3c23dd 81%);
}

.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #1c1e1f;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
}

.btn-grad:active {
  color: #1c1e1f;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-3 {
  margin-bottom: 30px;
}
